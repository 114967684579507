import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from 'api';

const initialState = {
  loading: false,
  devices: [],
  types: [],
  success: false,
  error: null,
  errorMessage: null,
};

export const deviceGet = createAsyncThunk('device/get', async ({ urlParam, queryParams }, { getState, rejectWithValue }) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().account.token,
    },
    params: queryParams,
  };
  try {
    let url = '/api/device';
    if (urlParam) {
      url += `/${urlParam}`;
    }
    const response = await axios.get(url, config);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const devicePost = createAsyncThunk('device/post', async ({ urlParam, queryParams, payload }, { getState, rejectWithValue }) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().account.token,
    },
    params: queryParams,
  };

  try {
    let url = '/api/device';
    if (urlParam) {
      url += `/${urlParam}`;
    }
    const response = axios.post(url, payload, config);
    return { ...response, payload };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const devicePut = createAsyncThunk('device/put', async ({ urlParam, queryParams, payload }, { getState, rejectWithValue }) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().account.token,
    },
    params: queryParams,
  };

  try {
    let url = '/api/device';
    if (urlParam) {
      url += `/${urlParam}`;
    }
    const response = await axios.put(url, payload, config);
    return { ...response, payload };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const deviceDelete = createAsyncThunk(
  'device/delete',
  async ({ deviceID, urlParam, queryParams }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };

    try {
      if (!deviceID) {
        throw new Error('Device ID is required');
      }

      let url = `/api/device/${deviceID}`;

      if (urlParam) {
        url += `/${urlParam}`;
      }
      const response = await axios.delete(url, config);
      return { ...response, deviceID };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deviceAccessPost = createAsyncThunk(
  'device/access/post',
  async ({ urlParam, queryParams, payload }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };

    try {
      let url = '/api/device/access';
      if (urlParam) {
        url += `/${urlParam}`;
      }
      const response = await axios.post(url, payload, config);
      return { ...response, payload };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deviceAccessPut = createAsyncThunk(
  'device/access/put',
  async ({ urlParam, queryParams, payload }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };

    try {
      let url = '/api/device/access';
      if (urlParam) {
        url += `/${urlParam}`;
      }
      const response = await axios.put(url, payload, config);
      return { ...response, payload };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deviceAccessDelete = createAsyncThunk(
  'device/access/delete',
  async ({ deviceID, organization, urlParam, queryParams }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };

    try {
      if (!deviceID || !organization) {
        throw new Error('Device ID and organization are required');
      }

      let url = `/api/device/access/${deviceID}/${organization}`;

      if (urlParam) {
        url += `/${urlParam}`;
      }
      const response = await axios.delete(url, config);
      return { ...response, deviceID, organization };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deviceTypeGet = createAsyncThunk('device/get/type', async ({ urlParam, queryParams }, { getState, rejectWithValue }) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().account.token,
    },
    params: queryParams,
  };
  try {
    let url = '/api/device/type';
    if (urlParam) {
      url += `/${urlParam}`;
    }
    const response = await axios.get(url, config);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const deviceTypePost = createAsyncThunk(
  'device/post/type',
  async ({ urlParam, queryParams, payload }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };

    try {
      let url = '/api/device/type';
      if (urlParam) {
        url += `/${urlParam}`;
      }
      const response = await axios.post(url, payload, config);
      return { ...response, payload };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deviceTypePut = createAsyncThunk(
  'device/put/type',
  async ({ urlParam, queryParams, payload }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };

    try {
      let url = '/api/device/type';
      if (urlParam) {
        url += `/${urlParam}`;
      }
      const response = await axios.put(url, payload, config);
      return { ...response, payload };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deviceTypeDelete = createAsyncThunk(
  'device/delete/type',
  async ({ deviceType, urlParam, queryParams }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };

    try {
      if (!deviceType) {
        throw new Error('Device type is required');
      }

      let url = `/api/device/type/${deviceType}`;

      if (urlParam) {
        url += `/${urlParam}`;
      }
      const response = await axios.delete(url, config);
      return { ...response, deviceType };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deviceMappingPost = createAsyncThunk(
  'device/post/mapping',
  async ({ urlParam, queryParams, payload }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };

    try {
      let url = '/api/device/mapping';
      if (urlParam) {
        url += `/${urlParam}`;
      }
      const response = await axios.post(url, payload, config);
      return { ...response, payload };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deviceMappingDelete = createAsyncThunk(
  'device/delete/mapping',
  async ({ deviceID, urlParam, queryParams }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };

    try {
      if (!deviceID) {
        throw new Error('Device ID is required');
      }

      const { database_name, topic, source } = queryParams;
      if (!database_name || !topic || !source) {
        throw new Error('Database name, topic, and source are required');
      }

      let url = `/api/device/mapping/${deviceID}`;
      if (urlParam) {
        url += `/${urlParam}`;
      }
      const response = await axios.delete(url, config);
      return { ...response, deviceID, database_name, topic, source };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const deviceSlice = createSlice({
  name: 'device',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(deviceGet.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(deviceGet.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;

      const newEntries = action.payload.data || [];

      state.devices = [
        ...state.devices.map((device) => {
          // Find a matching new entry based on device_id
          const matchingEntry = newEntries.find((newEntry) => newEntry.device_id === device.device_id);
          // If a match is found, replace the existing device with the new one, otherwise keep the old device
          return matchingEntry ? matchingEntry : device;
        }),
        // Add new entries that are not already in state.devices
        ...newEntries.filter((newEntry) => !state.devices.some((device) => device.device_id === newEntry.device_id)),
      ];

      // Sort the devices by device_name
      state.devices.sort((a, b) => a.device_name.localeCompare(b.device_name));
    });

    builder.addCase(deviceGet.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.errorMessage = action.payload;
    });

    builder.addCase(devicePost.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(devicePost.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      const newEntries = action.payload.payload?.devices || [];

      state.devices = [
        // Keep existing devices that are not in newEntries
        ...state.devices.filter((device) => !newEntries.some((newEntry) => newEntry.device_id === device.device_id)),
        // Add new or updated devices, ensuring 'access' is an empty array
        ...newEntries.map((newEntry) => ({
          ...newEntry,
          access: [], // Ensure 'access' is initialized as an empty array
          mapping: [],
        })),
      ];
    });

    builder.addCase(devicePost.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(devicePut.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(devicePut.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      const newEntries = action.payload.payload?.devices || [];

      state.devices = state.devices.map((device) => {
        const matchingEntry = newEntries.find((newEntry) => newEntry.device_id === device.device_id);

        if (matchingEntry) {
          // Merge the existing device data with the new data
          return {
            ...device,
            ...matchingEntry,
          };
        }

        // Return the device unchanged if no matching entry found
        return device;
      });
    });

    builder.addCase(devicePut.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(deviceDelete.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(deviceDelete.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;

      state.devices = (state.devices || []).filter((entry) => entry.device_id !== action.payload.deviceID);
    });

    builder.addCase(deviceDelete.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(deviceAccessPost.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(deviceAccessPost.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;

      const newAccessEntries = action.payload.payload?.deviceAccesses || [];

      state.devices = state.devices.map((device) => {
        // Find if there's a matching device in newAccessEntries
        const matchingAccess = newAccessEntries.filter((entry) => entry.device_id === device.device_id);

        if (matchingAccess.length > 0) {
          const updatedAccesses = matchingAccess.map((entry) => ({
            organization: entry.organization,
            permission: entry.permission,
          }));

          return {
            ...device,
            access: [...device.access, ...updatedAccesses],
          };
        }

        // If no match, return the device as-is
        return device;
      });
    });

    builder.addCase(deviceAccessPost.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(deviceAccessPut.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(deviceAccessPut.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;

      const newAccessEntries = action.payload.payload?.deviceAccesses || [];

      state.devices = state.devices.map((device) => {
        // Find matching access entries for the current device
        const matchingAccesses = newAccessEntries.filter((entry) => entry.device_id === device.device_id);

        if (matchingAccesses.length > 0) {
          const updatedAccesses = device.access.map((access) => {
            // Check if there is a matching organization to update the permission
            const matchingAccess = matchingAccesses.find((entry) => entry.organization === access.organization);
            if (matchingAccess) {
              return {
                organization: matchingAccess.organization,
                permission: matchingAccess.permission,
              };
            }
            // If no matching organization, keep the current access as is
            return access;
          });

          return {
            ...device,
            access: updatedAccesses,
          };
        }

        // Return the device as-is if no matching entries
        return device;
      });
    });

    builder.addCase(deviceAccessPut.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(deviceAccessDelete.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(deviceAccessDelete.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;

      const { deviceID, organization } = action.payload;

      state.devices = state.devices.map((device) => {
        if (device.device_id === deviceID) {
          return {
            ...device,
            // Filter out the access entry with the matching organization
            access: device.access.filter((item) => item.organization !== organization),
          };
        }
        // Return the device unchanged if no match
        return device;
      });
    });

    builder.addCase(deviceAccessDelete.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(deviceTypeGet.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(deviceTypeGet.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      const newEntries = action.payload.data?.data || [];

      // Create a new array of types by merging existing types and new entries
      state.types = [
        // Keep existing types, updating those that match with newEntries
        ...state.types.map((type) => {
          const matchingEntry = newEntries.find((newEntry) => newEntry.device_type === type.device_type);
          // If a matching newEntry is found, return it; otherwise, keep the existing type
          return matchingEntry ? matchingEntry : type;
        }),
        // Add new entries that do not already exist in state.types
        ...newEntries.filter((newEntry) => !state.types.some((type) => type.device_type === newEntry.device_type)),
      ];

      // Sort the types by device_type
      state.types.sort((a, b) => a.device_type.localeCompare(b.device_type));
    });

    builder.addCase(deviceTypeGet.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.errorMessage = action.payload;
    });

    builder.addCase(deviceTypePost.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(deviceTypePost.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      const newEntries = action.payload.payload?.deviceTypes || [];

      // Create a set of existing device types to avoid duplicates
      const existingDeviceTypes = new Set(state.types.map((type) => type.device_type));

      // Combine existing types with new entries, ensuring uniqueness
      state.types = [...state.types, ...newEntries.filter((newEntry) => !existingDeviceTypes.has(newEntry.device_type))];
    });

    builder.addCase(deviceTypePost.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(deviceTypePut.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(deviceTypePut.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;

      const newTypes = action.payload.payload?.deviceTypes || [];

      state.types = (state.types || []).map((entry) => {
        const matchingType = newTypes.find((type) => type.device_type === entry.device_type);

        // If there's a matching type, return the updated type; otherwise, return the existing one
        return matchingType ? matchingType : entry;
      });
    });

    builder.addCase(deviceTypePut.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(deviceTypeDelete.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(deviceTypeDelete.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.types = (state.types || []).filter((entry) => entry.device_type !== action.payload.deviceType);
    });

    builder.addCase(deviceTypeDelete.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(deviceMappingPost.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(deviceMappingPost.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;

      const newEntries = action.payload.payload?.deviceMappings || [];

      state.devices = state.devices.map((device) => {
        // Find if there's a matching device in newEntries
        const matchingMappings = newEntries.filter((entry) => entry.device_id === device.device_id);

        if (matchingMappings.length > 0) {
          const updatedMappings = matchingMappings.map((entry) => ({
            database_name: entry.database_name,
            topic: entry.topic,
            source: entry.source,
          }));

          return {
            ...device,
            // Ensure that mapping is initialized as an empty array if undefined
            mapping: [...device.mapping, ...updatedMappings],
          };
        }

        // Return the device unchanged if no matching mapping found
        return device;
      });
    });

    builder.addCase(deviceMappingPost.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(deviceMappingDelete.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(deviceMappingDelete.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;

      const { deviceID, database_name, topic, source } = action.payload;

      state.devices = state.devices.map((device) => {
        if (device.device_id === deviceID) {
          return {
            ...device,
            mapping: device.mapping.filter(
              (mapping) => mapping.database_name !== database_name || mapping.topic !== topic || mapping.source !== source
            ),
          };
        }
        return device;
      });
    });

    builder.addCase(deviceMappingDelete.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });
  },
});

export default deviceSlice.reducer;
