import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from 'api';

const initialState = {
  loading: false,
  services: [],
  success: false,
  containers: [],
  logs: '',
  stats: {},
  errorMessage: null,
};

export const serviceGet = createAsyncThunk('service/get', async ({ urlParam, queryParams }, { getState, rejectWithValue }) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().account.token,
    },
    params: queryParams,
  };
  try {
    let url = '/api/service';
    if (urlParam) {
      url += `/${urlParam}`;
    }
    const response = await axios.get(url, config);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const servicePost = createAsyncThunk('service/post', async ({ urlParam, queryParams, payload }, { getState, rejectWithValue }) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().account.token,
    },
    params: queryParams,
  };

  try {
    let url = '/api/service';
    if (urlParam) {
      url += `/${urlParam}`;
    }
    const response = await axios.post(url, payload, config);
    return {
      ...response,
      services: payload?.services,
    };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const servicePut = createAsyncThunk('service/put', async ({ urlParam, queryParams, payload }, { getState, rejectWithValue }) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().account.token,
    },
    params: queryParams,
  };

  try {
    let url = '/api/service';
    if (urlParam) {
      url += `/${urlParam}`;
    }
    const response = await axios.put(url, payload, config);
    return {
      ...response,
      services: payload?.services,
    };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const serviceDelete = createAsyncThunk(
  'service/delete',
  async ({ serviceID, urlParam, queryParams }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };

    try {
      if (!serviceID) {
        throw new Error('Service ID is required');
      }

      let url = `/api/service/${serviceID}`;

      if (urlParam) {
        url += `/${urlParam}`;
      }
      const response = await axios.delete(url, config);
      return {
        ...response,
        serviceID,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const servicePostContainerStart = createAsyncThunk(
  'service/post/container/start',
  async ({ serviceID, urlParam, queryParams, payload }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };

    try {
      if (!serviceID) {
        throw new Error('Service ID is required');
      }

      let url = `/api/service/start/${serviceID}`;

      if (urlParam) {
        url += `/${urlParam}`;
      }
      return await axios.post(url, payload, config);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const servicePostContainerStop = createAsyncThunk(
  'service/post/container/stop',
  async ({ serviceID, urlParam, queryParams, payload }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };

    try {
      if (!serviceID) {
        throw new Error('Service ID is required');
      }

      let url = `/api/service/stop/${serviceID}`;

      if (urlParam) {
        url += `/${urlParam}`;
      }
      return await axios.post(url, payload, config);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const serviceGetContainerList = createAsyncThunk(
  'service/get/container/list',
  async ({ urlParam, queryParams }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };
    try {
      let url = '/api/service/list';
      if (urlParam) {
        url += `/${urlParam}`;
      }
      return await axios.get(url, config);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const serviceGetContainerLogs = createAsyncThunk(
  'service/get/container/logs',
  async ({ serviceID, urlParam, queryParams }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };
    try {
      if (!serviceID) {
        throw new Error('Service ID is required');
      }

      let url = `/api/service/logs/${serviceID}`;

      if (urlParam) {
        url += `/${urlParam}`;
      }
      return await axios.get(url, config);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const serviceGetContainerStats = createAsyncThunk(
  'service/get/container/stats',
  async ({ serviceID, urlParam, queryParams }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };
    try {
      if (!serviceID) {
        throw new Error('Service ID is required');
      }

      let url = `/api/service/stats/${serviceID}`;

      if (urlParam) {
        url += `/${urlParam}`;
      }
      return await axios.get(url, config);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const serviceSlice = createSlice({
  name: 'service',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(serviceGet.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(serviceGet.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      const newEntries = action.payload?.data || [];

      newEntries.forEach((entry) => {
        const existingIndex = state.services.findIndex((service) => service.service_id === entry.service_id);
        let service = {
          ...entry,
          environment: entry.environment ? JSON.parse(entry.environment) : {},
        };

        if (existingIndex !== -1) {
          state.services[existingIndex] = service;
        } else {
          state.services = [...state.services, service];
        }
      });
    });

    builder.addCase(serviceGet.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.services = [];
      state.errorMessage = action.payload;
    });

    builder.addCase(servicePost.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(servicePost.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;

      const insertedIds = action.payload.data?.data || [];

      const newServices = action.payload.services.map((service, index) => {
        return {
          ...service,
          service_id: insertedIds[index],
        };
      });

      state.services = [...state.services, ...newServices];
    });

    builder.addCase(servicePost.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(servicePut.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(servicePut.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;

      action.payload.services.forEach((service) => {
        const existingIndex = state.services.findIndex((s) => s.service_id === service.service_id);
        if (existingIndex !== -1) {
          state.services[existingIndex] = service;
        }
      });
    });

    builder.addCase(servicePut.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(serviceDelete.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(serviceDelete.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.services = state.services.filter((service) => service.service_id !== action.payload.serviceID);
    });

    builder.addCase(serviceDelete.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(servicePostContainerStart.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(servicePostContainerStart.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
    });

    builder.addCase(servicePostContainerStart.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(servicePostContainerStop.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(servicePostContainerStop.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
    });

    builder.addCase(servicePostContainerStop.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(serviceGetContainerList.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(serviceGetContainerList.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.containers = action.payload.data;
    });

    builder.addCase(serviceGetContainerList.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.containers = [];
      state.errorMessage = action.payload;
    });

    builder.addCase(serviceGetContainerLogs.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(serviceGetContainerLogs.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.logs = action.payload.data;
    });

    builder.addCase(serviceGetContainerLogs.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.logs = '';
      state.errorMessage = action.payload;
    });

    builder.addCase(serviceGetContainerStats.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(serviceGetContainerStats.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.stats = action.payload.data;
    });

    builder.addCase(serviceGetContainerStats.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.stats = {};
      state.errorMessage = action.payload;
    });
  },
});

export default serviceSlice.reducer;
