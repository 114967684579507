import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from 'api';

const initialState = {
  loading: false,
  locations: [],
  types: [],
  success: false,
  error: null,
  errorMessage: null,
};

export const locationGet = createAsyncThunk('location/get', async ({ urlParam, queryParams }, { getState, rejectWithValue }) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().account.token,
    },
    params: queryParams,
  };
  try {
    let url = '/api/location';
    if (urlParam) {
      url += `/${urlParam}`;
    }
    const response = await axios.get(url, config);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const locationPost = createAsyncThunk('location/post', async ({ urlParam, queryParams, payload }, { getState, rejectWithValue }) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().account.token,
    },
    params: queryParams,
  };

  try {
    let url = '/api/location';
    if (urlParam) {
      url += `/${urlParam}`;
    }
    const response = await axios.post(url, payload, config);
    return { ...response, payload };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const locationPut = createAsyncThunk('location/put', async ({ urlParam, queryParams, payload }, { getState, rejectWithValue }) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().account.token,
    },
    params: queryParams,
  };

  try {
    let url = '/api/location';
    if (urlParam) {
      url += `/${urlParam}`;
    }
    const response = await axios.put(url, payload, config);
    return { ...response, payload };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const locationDelete = createAsyncThunk(
  'location/delete',
  async ({ locationID, urlParam, queryParams }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };

    try {
      if (!locationID) {
        throw new Error('Location ID is required');
      }

      let url = `/api/location/${locationID}`;

      if (urlParam) {
        url += `/${urlParam}`;
      }
      const response = await axios.delete(url, config);
      return { ...response, locationID };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const locationAccessPost = createAsyncThunk(
  'location/access/post',
  async ({ urlParam, queryParams, payload }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };

    try {
      let url = '/api/location/access';
      if (urlParam) {
        url += `/${urlParam}`;
      }
      const response = await axios.post(url, payload, config);
      return { ...response, payload };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const locationAccessPut = createAsyncThunk(
  'location/access/put',
  async ({ urlParam, queryParams, payload }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };

    try {
      let url = '/api/location/access';
      if (urlParam) {
        url += `/${urlParam}`;
      }
      const response = await axios.put(url, payload, config);
      return { ...response, payload };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const locationAccessDelete = createAsyncThunk(
  'location/access/delete',
  async ({ locationID, organization, urlParam, queryParams }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };

    try {
      if (!locationID || !organization) {
        throw new Error('Location ID and organization are required');
      }

      let url = `/api/location/access/${locationID}/${organization}`;

      if (urlParam) {
        url += `/${urlParam}`;
      }
      const response = await axios.delete(url, config);
      return { ...response, locationID, organization };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const locationTypeGet = createAsyncThunk('location/get/type', async ({ urlParam, queryParams }, { getState, rejectWithValue }) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().account.token,
    },
    params: queryParams,
  };
  try {
    let url = '/api/location/type';
    if (urlParam) {
      url += `/${urlParam}`;
    }
    const response = await axios.get(url, config);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const locationTypePost = createAsyncThunk(
  'location/post/type',
  async ({ urlParam, queryParams, payload }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };

    try {
      let url = '/api/location/type';
      if (urlParam) {
        url += `/${urlParam}`;
      }
      const response = await axios.post(url, payload, config);
      return { ...response, payload };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const locationTypePut = createAsyncThunk(
  'location/put/type',
  async ({ urlParam, queryParams, payload }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };

    try {
      let url = '/api/location/type';
      if (urlParam) {
        url += `/${urlParam}`;
      }
      const response = await axios.put(url, payload, config);
      return { ...response, payload };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const locationTypeDelete = createAsyncThunk(
  'location/delete/type',
  async ({ locationType, urlParam, queryParams }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };

    try {
      if (!locationType) {
        throw new Error('Location type is required');
      }

      let url = `/api/location/type/${locationType}`;
      if (urlParam) {
        url += `/${urlParam}`;
      }
      const response = await axios.delete(url, config);
      return { ...response, locationType };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(locationGet.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(locationGet.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;

      const newEntries = action.payload.data || [];

      state.locations = [
        ...state.locations.map((location) => {
          // Find a matching new entry based on location_id
          const matchingEntry = newEntries.find((newEntry) => newEntry.location_id === location.location_id);
          // If a match is found, replace the existing location with the new one, otherwise keep the old location
          return matchingEntry ? matchingEntry : location;
        }),
        // Add new entries that are not already in state.locations
        ...newEntries.filter((newEntry) => !state.locations.some((location) => location.location_id === newEntry.location_id)),
      ];

      // Sort the locations by location_name
      state.locations.sort((a, b) => a.location_name.localeCompare(b.location_name));
    });

    builder.addCase(locationGet.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.locations = [];
      state.errorMessage = action.payload;
    });

    builder.addCase(locationPost.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(locationPost.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      const newEntries = action.payload.payload?.locations || [];

      state.locations = [
        // Keep existing locations that are not in newEntries
        ...state.locations.filter((location) => !newEntries.some((newEntry) => newEntry.location_id === location.location_id)),
        // Add new or updated locations, ensuring 'access' is an empty array
        ...newEntries.map((newEntry) => ({
          ...newEntry,
          access: [], // Ensure 'access' is initialized as an empty array
          mapping: [],
        })),
      ];
    });

    builder.addCase(locationPost.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(locationPut.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(locationPut.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      const newEntries = action.payload.payload?.locations || [];

      state.locations = state.locations.map((location) => {
        const matchingEntry = newEntries.find((newEntry) => newEntry.location_id === location.location_id);

        if (matchingEntry) {
          // Merge the existing location data with the new data
          return {
            ...location,
            ...matchingEntry,
          };
        }

        // Return the location unchanged if no matching entry found
        return location;
      });
    });

    builder.addCase(locationPut.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(locationDelete.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(locationDelete.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;

      state.locations = (state.locations || []).filter((entry) => entry.location_id !== action.payload.locationID);
    });

    builder.addCase(locationDelete.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(locationAccessPost.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(locationAccessPost.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;

      const newAccessEntries = action.payload.payload?.locationAccesses || [];

      state.locations = state.locations.map((location) => {
        // Find if there's a matching location in newAccessEntries
        const matchingAccess = newAccessEntries.filter((entry) => entry.location_id === location.location_id);

        if (matchingAccess.length > 0) {
          const updatedAccesses = matchingAccess.map((entry) => ({
            organization: entry.organization,
            permission: entry.permission,
          }));

          return {
            ...location,
            access: [...location.access, ...updatedAccesses],
          };
        }

        // If no match, return the location as-is
        return location;
      });
    });

    builder.addCase(locationAccessPost.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(locationAccessPut.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(locationAccessPut.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;

      const newAccessEntries = action.payload.payload?.locationAccesses || [];

      state.locations = state.locations.map((location) => {
        // Find matching access entries for the current location
        const matchingAccesses = newAccessEntries.filter((entry) => entry.location_id === location.location_id);

        if (matchingAccesses.length > 0) {
          const updatedAccesses = location.access.map((access) => {
            // Check if there is a matching organization to update the permission
            const matchingAccess = matchingAccesses.find((entry) => entry.organization === access.organization);
            if (matchingAccess) {
              return {
                organization: matchingAccess.organization,
                permission: matchingAccess.permission,
              };
            }
            // If no matching organization, keep the current access as is
            return access;
          });

          return {
            ...location,
            access: updatedAccesses,
          };
        }

        // Return the location as-is if no matching entries
        return location;
      });
    });

    builder.addCase(locationAccessPut.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(locationAccessDelete.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(locationAccessDelete.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;

      const { locationID, organization } = action.payload;

      state.locations = state.locations.map((location) => {
        if (location.location_id === locationID) {
          return {
            ...location,
            // Filter out the access entry with the matching organization
            access: location.access.filter((item) => item.organization !== organization),
          };
        }
        // Return the location unchanged if no match
        return location;
      });
    });

    builder.addCase(locationAccessDelete.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(locationTypeGet.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(locationTypeGet.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      const newEntries = action.payload.data?.data || [];

      // Create a new array of types by merging existing types and new entries
      state.types = [
        // Keep existing types, updating those that match with newEntries
        ...state.types.map((type) => {
          const matchingEntry = newEntries.find((newEntry) => newEntry.location_type === type.location_type);
          // If a matching newEntry is found, return it; otherwise, keep the existing type
          return matchingEntry ? matchingEntry : type;
        }),
        // Add new entries that do not already exist in state.types
        ...newEntries.filter((newEntry) => !state.types.some((type) => type.location_type === newEntry.location_type)),
      ];

      // Sort the types by location_type
      state.types.sort((a, b) => a.location_type.localeCompare(b.location_type));
    });

    builder.addCase(locationTypeGet.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.errorMessage = action.payload;
    });

    builder.addCase(locationTypePost.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(locationTypePost.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      const newEntries = action.payload.payload?.locationTypes || [];

      // Create a set of existing location types to avoid duplicates
      const existingLocationTypes = new Set(state.types.map((type) => type.location_type));

      // Combine existing types with new entries, ensuring uniqueness
      state.types = [...state.types, ...newEntries.filter((newEntry) => !existingLocationTypes.has(newEntry.location_type))];
    });

    builder.addCase(locationTypePost.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(locationTypePut.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(locationTypePut.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      const newTypes = action.payload.payload?.locationTypes || [];

      state.types = (state.types || []).map((entry) => {
        const matchingType = newTypes.find((type) => type.location_type === entry.location_type);

        // If there's a matching type, return the updated type; otherwise, return the existing one
        return matchingType ? matchingType : entry;
      });
    });

    builder.addCase(locationTypePut.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(locationTypeDelete.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(locationTypeDelete.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.types = (state.types || []).filter((entry) => entry.location_type !== action.payload.locationType);
    });

    builder.addCase(locationTypeDelete.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });
  },
});

export default locationSlice.reducer;
