import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from 'api';

const initialState = {
  configurations: [],
  error: null,
  loading: false,
  organizations: [],
  requests: [],
  search: [],
  success: false,
};

export const organizationGet = createAsyncThunk('organization/get', async ({ urlParam, queryParams }, { getState, rejectWithValue }) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().account.token,
    },
    params: queryParams,
  };
  try {
    let url = '/api/organization';
    if (urlParam) {
      url += `/${urlParam}`;
    }
    const response = await axios.get(url, config);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const organizationPost = createAsyncThunk(
  'organization/post',
  async ({ urlParam, queryParams, payload }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };

    try {
      let url = '/api/organization';
      if (urlParam) {
        url += `/${urlParam}`;
      }

      const response = await axios.post(url, payload, config);
      return { ...response, payload };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const organizationPut = createAsyncThunk(
  'organization/put',
  async ({ urlParam, queryParams, payload }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };

    try {
      let url = '/api/organization';
      if (urlParam) {
        url += `/${urlParam}`;
      }

      const response = await axios.put(url, payload, config);
      return { ...response, payload };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const organizationStatusPut = createAsyncThunk(
  'organization/status/put',
  async ({ urlParam, queryParams, payload }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };

    try {
      let url = '/api/organization/status';
      if (urlParam) {
        url += `/${urlParam}`;
      }

      const response = await axios.put(url, payload, config);
      return { ...response, payload };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const organizationConfigurationGet = createAsyncThunk(
  'organization/configuration/get',
  async ({ urlParam, queryParams }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };
    try {
      let url = '/api/organization/configuration';
      if (urlParam) {
        url += `/${urlParam}`;
      }
      const response = await axios.get(url, config);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const organizationConfigurationPost = createAsyncThunk(
  'organization/configuration/post',
  async ({ urlParam, queryParams, payload }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };

    try {
      let url = '/api/organization/configuration';
      if (urlParam) {
        url += `/${urlParam}`;
      }

      const response = await axios.post(url, payload, config);
      return { ...response, payload };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const organizationConfigurationPut = createAsyncThunk(
  'organization/configuration/put',
  async ({ urlParam, queryParams, payload }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };

    try {
      let url = '/api/organization/configuration';
      if (urlParam) {
        url += `/${urlParam}`;
      }

      const response = await axios.put(url, payload, config);
      return { ...response, payload };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const organizationSearch = createAsyncThunk(
  'organization/search',
  async ({ urlParam, queryParams, signal }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
      signal,
    };
    try {
      let url = '/api/organization/search';
      if (urlParam) {
        url += `/${urlParam}`;
      }
      const response = await axios.get(url, config);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const organizationJoinPost = createAsyncThunk(
  'organization/join/put',
  async ({ organization, urlParam, queryParams, payload }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };

    try {
      if (!organization) {
        throw new Error('Organization is required');
      }

      let url = `/api/organization/join/${organization}`;
      if (urlParam) {
        url += `/${urlParam}`;
      }

      const response = await axios.post(url, payload, config);
      return {
        ...response,
        organization,
        userID: getState().account.account?.user_id,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const organizationRequestGet = createAsyncThunk(
  'organization/request/get',
  async ({ urlParam, queryParams }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };
    try {
      let url = '/api/organization/request';
      if (urlParam) {
        url += `/${urlParam}`;
      }
      const response = await axios.get(url, config);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const organizationRequestProcessPost = createAsyncThunk(
  'organization/request/process/put',
  async ({ requestID, urlParam, queryParams, payload }, { getState, rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getState().account.token,
      },
      params: queryParams,
    };

    try {
      if (!requestID) {
        throw new Error('Request ID is required');
      }

      let url = `/api/organization/request/process/${requestID}`;
      if (urlParam) {
        url += `/${urlParam}`;
      }

      const response = await axios.post(url, payload, config);
      return {
        ...response,
        requestID,
        payload,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(organizationGet.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(organizationGet.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      const newEntries = action.payload.data || [];

      state.organizations = [
        ...state.organizations.map((organization) => {
          // Find a matching new entry based on organization
          const matchingEntry = newEntries.find((newEntry) => newEntry.organization === organization.organization);
          // If a match is found, replace the existing organization with the new one, otherwise keep the old organization
          return matchingEntry ? matchingEntry : organization;
        }),
        // Add new entries that are not already in state.organizations
        ...newEntries.filter(
          (newEntry) => !state.organizations.some((organization) => organization.organization === newEntry.organization)
        ),
      ];

      // Sort the organizations by description
      state.organizations.sort((a, b) => a.description.localeCompare(b.description));
    });

    builder.addCase(organizationGet.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action.error.message;
    });

    builder.addCase(organizationPost.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(organizationPost.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      const newEntries = action.payload.payload?.organizations || [];

      state.organizations = [
        // Keep existing organizations that are not in newEntries
        ...state.organizations.filter(
          (organization) => !newEntries.some((newEntry) => newEntry.organization === organization.organization)
        ),
        // Add new organizations
        ...newEntries.map((organization) => ({
          ...organization,
          status: 'DISABLED',
        })),
      ];

      // Sort the organizations by description
      state.organizations.sort((a, b) => a.description.localeCompare(b.description));
    });

    builder.addCase(organizationPost.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(organizationPut.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(organizationPut.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      const newEntries = action.payload.payload?.organizations || [];

      state.organizations = state.organizations.map((organization) => {
        const matchingEntry = newEntries.find((newEntry) => newEntry.organization === organization.organization);

        if (matchingEntry) {
          // Merge the existing organization data with the new data
          return {
            ...organization,
            ...matchingEntry,
          };
        }

        // Return the organization unchanged if no matching entry found
        return organization;
      });
    });

    builder.addCase(organizationPut.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(organizationStatusPut.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(organizationStatusPut.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      const newEntries = action.payload.payload?.organizationStatus || [];

      state.organizations = state.organizations.map((organization) => {
        const matchingEntry = newEntries.find((newEntry) => newEntry.organization === organization.organization);

        if (matchingEntry) {
          // Merge the existing organization data with the new data
          return {
            ...organization,
            status: matchingEntry.status,
          };
        }

        // Return the organization unchanged if no matching entry found
        return organization;
      });
    });

    builder.addCase(organizationStatusPut.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(organizationConfigurationGet.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(organizationConfigurationGet.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      action.payload.data.map((config) => {
        const index = state.configurations.findIndex((cfg) => cfg.organization === config.organization);
        if (index !== -1) {
          state.configurations[index] = config;
        } else {
          state.configurations.push(config);
        }
      });

      state.configurations.sort((a, b) => a.organization.localeCompare(b.organization));
    });

    builder.addCase(organizationConfigurationGet.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action.error.message;
    });

    builder.addCase(organizationConfigurationPost.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(organizationConfigurationPost.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      const newEntries = action.payload.payload?.organizationConfigurations || [];

      state.configurations = [
        // Keep existing configurations that are not in newEntries
        ...state.configurations.filter(
          (configuration) => !newEntries.some((newEntry) => newEntry.organization === configuration.organization)
        ),
        // Add new configurations
        ...newEntries,
      ];
    });

    builder.addCase(organizationConfigurationPost.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(organizationConfigurationPut.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(organizationConfigurationPut.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      const newEntries = action.payload.payload?.organizationConfigurations || [];

      state.configurations = state.configurations.map((configuration) => {
        const matchingEntry = newEntries.find((newEntry) => newEntry.organization === configuration.organization);

        if (matchingEntry) {
          // Merge the existing configuration data with the new data
          return {
            ...configuration,
            ...matchingEntry,
          };
        }

        // Return the configuration unchanged if no matching entry found
        return configuration;
      });
    });

    builder.addCase(organizationConfigurationPut.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(organizationSearch.pending, (state) => {
      state.loading = true;
      state.success = false;
    });

    builder.addCase(organizationSearch.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.search = action.payload.data;
    });

    builder.addCase(organizationSearch.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action.error.message;
      state.search = [];
    });

    builder.addCase(organizationJoinPost.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(organizationJoinPost.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      const { data, organization, userID } = action.payload;
      const { request_id } = data?.data || {};

      state.requests = [
        // Keep existing requests that are not for the same organization or user
        ...state.requests.filter((request) => request.organization !== organization || request.user_id !== userID),
        // Add new request
        {
          request_id,
          user_id: userID,
          organization,
          status: 'pending',
        },
      ];
    });

    builder.addCase(organizationJoinPost.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });

    builder.addCase(organizationRequestGet.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(organizationRequestGet.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      const newEntries = action.payload.data || [];

      state.requests = [
        ...state.requests.map((request) => {
          // Find a matching new entry based on request_id
          const matchingEntry = newEntries.find((newEntry) => newEntry.request_id === request.request_id);
          // If a match is found, replace the existing request with the new one, otherwise keep the old request
          return matchingEntry ? matchingEntry : request;
        }),
        // Add new entries that are not already in state.requests
        ...newEntries.filter((newEntry) => !state.requests.some((request) => request.request_id === newEntry.request_id)),
      ];

      // Sort the requests by request_id
      state.requests.sort((a, b) => a.request_id - b.request_id);
    });

    builder.addCase(organizationRequestGet.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action.error.message;
    });

    builder.addCase(organizationRequestProcessPost.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(organizationRequestProcessPost.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      const { requestID } = action.payload;
      const { action: actionPayload } = action.payload.payload;

      if (actionPayload === 'approve') {
        state.requests = (state.requests || []).filter((request) => request.request_id !== requestID);
      } else if (actionPayload === 'reject') {
        state.requests = [
          // Keep existing requests except the one that was rejected
          ...state.requests.filter((request) => request.request_id !== requestID),
          // Add the rejected request with the new status
          {
            ...state.requests.find((request) => request.request_id === requestID),
            status: 'rejected',
          },
        ];
      }
    });

    builder.addCase(organizationRequestProcessPost.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.errorMessage = action.payload;
    });
  },
});

export default organizationSlice.reducer;
